<template>
    <b-card class="blog-edit-wrapper">
      <!-- form -->
      <div class="text-center">
        <h4 class="mt-5">
          Update Funded Logo
          <feather-icon icon="Edit2Icon" />
        </h4>
      </div>
      <validation-observer ref="NgosFormvalidate">
        <b-form
          class="mt-2"
          style="width: 100%"
          @submit.prevent="save"
        >
          <b-row>
         
            <b-col md="4">
              <b-form-group
                label="link"
                label-for="blog-edit-link"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="link"
                  rules="required"
                >
                  <b-form-input
                    id="blog-edit-link"
                    v-model="NgosForm.link"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
  
            <b-col
              cols="12"
              class=""
            >
              <div
                class="border rounded p-2"
                style="margin:0px"
              >
                <h4 class="mb-1">
                  image
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      
                      :src="`https://lpcentre.com/${NgosForm.image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
  
                    <b-card-text class="my-50" />
                    <div class="d-inline-block">
                      <b-form-group
                        label=" image"
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Tab Image "
                          rules="required"
                        >
                          <b-form-file
                            ref="refInputE2"
                            v-model="NgosForm.image"
                            accept=".jpg, .png, .gif, .jpeg"
                            placeholder="Choose file"
                            @input="inputImageRendererTab"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Image Caption"
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <b-form-input
                          id="alt-image"
                          v-model="NgosForm.alt_image"
                        />
                      </b-form-group>
                    </div>
                  </b-media-body>
                </b-media>
              </div>
  
            </b-col>
  
            <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
           
            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>


            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Back
            </b-button>
          </b-col>
          </b-row>
  
        </b-form>
      </validation-observer>
      <!--/ form -->
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BMedia,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BButton,
    BFormTags,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { quillEditor } from 'vue-quill-editor'
  import { required, email } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
  import { ref, reactive } from '@vue/composition-api'
  import Vue from 'vue'
  import Editor from '@tinymce/tinymce-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import { useRouter } from '@core/utils/utils'
  // Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

  export default {
    setup() {
      // const store = useStore();
      const refInputEl = ref(null)
      const refInputE2 = ref(null)
      const refPreviewEl = ref(null)
      const refPreviewE2 = ref(null)
      const VisitorList = ref([])
      const Tabimage = ref('media/svg/files/blank-image.svg')
      const isLoading = ref(false)
      const { route } = useRouter()
      const { id } = route.value.params
      const form = ref({
        description: '',
        title: '',
  
        image: '',
        alt_image: '',
  
  
      })
      const { ngoId } = route.value.params
      const NgosForm = reactive(form)
      store.dispatch('setting/getLogoNgo',{ id }).then(response => {
     
      form.value = response.data.data

     
    })
      const { inputImageRenderer } = useInputImageRenderer(
        refInputEl,
        base64 => {
          refPreviewEl.value.src = base64
        },
      )
      const { inputImageRendererTab } = useInputImageRenderer(
        refInputE2,
        base64 => {
          refPreviewE2.value.src = base64
        },
      )
      const NgosFormvalidate = ref()
      // store.dispatch('setting/getAccerditedPage').then(response => {
      //   form.value = response.page_content
  
      //   form.value.meta_keywords = response.page_content.meta_keywords.split(',')
      // })
  
      const save = () => {
        const formData = new FormData()
  
        NgosFormvalidate.value.validate().then(success => {
          if (success) {
            isLoading.value = true
        
            formData.append('link', NgosForm.value.link)
         
            formData.append('image', NgosForm.value.image)
   
            formData.append('alt_image', NgosForm.value.alt_image)
          
            formData.append("_method", "put");
  
            store.dispatch('setting/updateLogoNgo', { id, formData })
              .then(response => {
                isLoading.value = false
                Vue.swal({
                  title: ' Added ',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }).catch(error => {
                Vue.swal({
                  title: '',
                  text: `${error.response.data.message}`,
                  icon: 'error',
                  confirmButtonText: 'ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          }
        })
      }
      const removeSpace = target => {
        NgosForm.value.slug = target.toLowerCase() // LowerCase
          .replace(/\s+/g, '-') // space to -
          .replace(/&/g, '') // & to and
          .replace(/--/g, '-')
          .replace(/[.,]/g, '-')
          .replace(/[_]/g, '')
          .replace(/[!''""=]/g, '')
          .replace(/[!?%$^~*#()+]/g, '')
      }
      return {
        id,
        ngoId,
        isLoading,
        removeSpace,
        refInputEl,
        refPreviewEl,
        refInputE2,
        refPreviewE2,
        inputImageRendererTab,
        Tabimage,
        NgosForm,
        inputImageRenderer,
        NgosFormvalidate,
        VisitorList,
        form,
  
        save,
        required,
        email,
      }
    },
  
    components: {
      BCard,
      vSelect,
      BMedia,
      ValidationProvider,
      ValidationObserver,
      BCardText,
      BMediaAside,
      BMediaBody,
  
      BForm,
  
      BFormTags,
      BImg,
      BRow,
      BCol,
      BButton,
      BFormGroup,
      BFormInput,
      BFormFile,
      Editor,
      quillEditor,
    },
  
    directives: {
      Ripple,
    },
  }
  </script>
  
    <style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
    @import "@core/scss/vue/libs/quill.scss";
    @import "@core/scss/vue/pages/page-blog.scss";
    </style>
  